import React from "react"

import { Layout, Seo } from "../components"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <section className="section has-background-white">
      <div className="container content">
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
